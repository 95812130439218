import React, { FC } from 'react'
import logoSrc from 'src/assets/logo-full.svg'

const NotFoundPage: FC = () => {
  return (
    <div className="flex flex-col flex-1 min-h-full pt-16 pb-12 bg-white">
      <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-center flex-shrink-0">
          <a href="/" className="inline-flex">
            <span className="sr-only">Inszu</span>
            <img className="w-auto h-12" src={logoSrc} alt="logo" />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold tracking-wide uppercase text-sky-600">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-neutral-900 sm:text-5xl">
              Page not found.
            </h1>
            <p className="mt-2 text-base text-neutral-500">
              Sorry, we couldn&apos;t find the page you&apos;re looking for.
            </p>
          </div>
        </div>
      </main>
    </div>
  )
}

export default NotFoundPage
